<script>
import Layout from "@/router/layouts/main";
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
// import axios from "axios";
// import Swal from "sweetalert2";

/**
 * Dashboard Component
 */
export default {
  page: {
    title: "Dashboard",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
  },
  data() {
    return {
      title: "Edit Soal",
      items: [
        {
          text: "Master Soal",
          href: "/",
        },
        {
          text: "Konfigurasi Soal",
          href: "/",
        },
        {
          text: "Edit Soal",
          active: true,
        },
      ],
      namePathUrl: this.$route.name,
      sessionRoleId: localStorage.session_role_active_id,
      user_name: localStorage.session_name,
      pathPhoto: localStorage.session_path_photo,
      pathPhotoFull:
        process.env.VUE_APP_BACKEND_URL + localStorage.session_path_photo,
      transactions: [
        {
          id: "#SK2540",
          name: "Neal Matthews",
          date: "07 Oct, 2019",
          total: "$400",
          status: "Paid",
          payment: ["fa-cc-mastercard", "Mastercard"],
          index: 1,
        },
        {
          id: "#SK2541",
          name: "Jamal Burnett",
          date: "07 Oct, 2019",
          total: "$380",
          status: "Chargeback",
          payment: ["fa-cc-visa", "Visa"],
          index: 2,
        },
        {
          id: "#SK2542",
          name: "Juan Mitchell",
          date: "06 Oct, 2019",
          total: "$384",
          status: "Paid",
          payment: ["fab fa-cc-paypal", "Paypal"],
          index: 3,
        },
        {
          id: "#SK2543",
          name: "Barry Dick",
          date: "05 Oct, 2019",
          total: "$412",
          status: "Paid",
          payment: ["fa-cc-mastercard", "Mastercard"],
          index: 4,
        },
        {
          id: "#SK2544",
          name: "Ronald Taylor",
          date: "04 Oct, 2019",
          total: "$404",
          status: "Refund",
          payment: ["fa-cc-visa", "Visa"],
          index: 5,
        },
        {
          id: "#SK2545",
          name: "Jacob Hunter",
          date: "04 Oct, 2019",
          total: "$392",
          status: "Paid",
          payment: ["fab fa-cc-paypal", "Paypal"],
          index: 6,
        },
      ],
      is_kunciJawaban: null,
    };
  },
  // mounted() {
  //   let self = this;
  //   self.cekAksesMenu();
  // },
  // methods: {
  //   cekAksesMenu() {
  //     let self = this;
  //     var config_axios = {
  //       method: "get",
  //       url: process.env.VUE_APP_BACKEND_URL_VERSION + "auth/check-access-menu",
  //       params: {
  //         role_id: self.sessionRoleId,
  //         link_name: self.namePathUrl,
  //       },
  //       headers: {
  //         Accept: "application/json",
  //         Authorization: "Bearer " + localStorage.access_token,
  //       },
  //     };
  //     axios(config_axios)
  //       .then((response) => {
  //         let response_data = response.data;
  //         let response_data_fix = response_data.data.status_access;
  //         if (response_data.meta.code == 200) {
  //           if (response_data_fix == false) {
  //             let timerInterval;
  //             Swal.fire({
  //               icon: "warning",
  //               title: "Oppss",
  //               text: "Mohon maaf anda tidak diberikan akses pada halaman ini.",
  //               timer: 2000,
  //               timerProgressBar: true,
  //               showCancelButton: false,
  //               showConfirmButton: false,
  //               didOpen: () => {
  //                 timerInterval = setInterval(() => {
  //                   const content = Swal.getContent();
  //                   if (content) {
  //                     const b = content.querySelector("b");
  //                     if (b) {
  //                       b.textContent = Swal.getTimerLeft();
  //                     }
  //                   }
  //                 }, 100);
  //               },
  //               willClose: () => {
  //                 clearInterval(timerInterval);
  //               },
  //             }).then((result) => {
  //               /* Read more about handling dismissals below */
  //               if (result.dismiss === Swal.DismissReason.timer) {
  //                 self.$router.push({ name: "dashboard" });
  //               }
  //             });
  //           }
  //         } else {
  //           Swal.fire({
  //             icon: "error",
  //             title: "Oops...",
  //             text: response_data_fix.data.message,
  //           });
  //         }
  //       })
  //       .catch((errors) => {
  //         console.log(errors);
  //       });
  //   },
  // },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-lg-6">
                <div>Kategori :</div>
                <div>Pilihan Ganda</div>
                <div>Soal :</div>
                <div>Pilih dari pilihan berikut :</div>
              </div>
            </div>
            <div class="row mt-4">
              <div class="col-lg-6">
                <div class="card mb-0">
                  <div class="card-header border">
                    Tambah Jadwal Pilihan Ganda
                  </div>
                  <div class="card-body border">
                    <b-form-group
                      label="Jawaban Pilihan 1"
                      label-for="pilihan1"
                      invalid-feedback="Jawaban Pilihan 1 is required"
                    >
                      <b-form-input id="pilihan1" required></b-form-input>
                    </b-form-group>
                    <b-form-group
                      label="Jawaban Pilihan 2"
                      label-for="pilihan2"
                      invalid-feedback="Jawaban Pilihan 2 is required"
                    >
                      <b-form-input id="pilihan2" required></b-form-input>
                    </b-form-group>
                    <b-form-group
                      label="Jawaban Pilihan 3"
                      label-for="pilihan3"
                      invalid-feedback="Jawaban Pilihan 3 is required"
                    >
                      <b-form-input id="pilihan3" required></b-form-input>
                    </b-form-group>
                    <b-form-group
                      label="Jawaban Pilihan 4"
                      label-for="pilihan4"
                      invalid-feedback="Jawaban Pilihan 4 is required"
                    >
                      <b-form-input id="pilihan4" required></b-form-input>
                    </b-form-group>
                    <b-form-group
                      label="Studi Kasus"
                      label-for="studi"
                      invalid-feedback="Studi Kasus is required"
                    >
                      <b-form-textarea
                        id="studi"
                        placeholder=""
                        rows="3"
                        max-rows="6"
                      ></b-form-textarea>
                    </b-form-group>
                    <b-form-group label="Upload Gambar" label-for="upload">
                      <input type="file">
                    </b-form-group>
                  </div>
                </div>
              </div>
              <div class="col-lg-6">
                <div class="card">
                  <div class="card-header border">Kunci Jawaban</div>
                  <div class="card-body border">
                    <b-form-select v-model="is_kunciJawaban">
                      <b-form-select-option value="">
                        -- pillih Kunci Jawaban --
                      </b-form-select-option>
                      <b-form-select-option value="1"
                        >Pilihan 1</b-form-select-option
                      >
                      <b-form-select-option value="2"
                        >Pilihan 2</b-form-select-option
                      >
                      <b-form-select-option value="3"
                        >Pilihan 3</b-form-select-option
                      >
                      <b-form-select-option value="4"
                        >Pilihan 4</b-form-select-option
                      >
                    </b-form-select>
                  </div>
                </div>
              </div>
              <div class="col-lg-12">
                <button
                type="button"
                class="btn btn-primary float-end"
                @click="save()"
              >
                Simpan
              </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
  </Layout>
</template>
